<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-4"></div>
      <div class="col-12 col-sm-4">
        <!-- Tabs -->
        <div class="text-center mt-2 mb-2">
        </div>
      </div>
      <div class="col-12 col-sm-4"></div>
    </div>

    <div class="row">
      <div class="col">
        <div class="body-section">
          <div class="body-wrapper">
            <!-- Tab Content -->
            <transition name="fade" mode="out-in">
              <div>
                <moderator-cards :colSize="moderatorColSize"
                                 :data="educators"
                                 :educatorsCurrentPage="educatorsCurrentPage"
                                 :educatorsLastPage="educatorsLastPage"
                                 @more="loadMoreEducators">
                </moderator-cards>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const ModeratorCards = () =>
  import('@/components/user/moderator/ModeratorCards.vue');
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name  : 'ModeratorList',
    props : {
      moderators : Array,
      colSize    : Number,
    },
    components : {
      ModeratorCards,
    },
    data() {
      return {
        educators            : [],
        educatorsPerPage     : 8,
        educatorsCurrentPage : 0,
        educatorsLastPage    : 0,
      }
    },
    mounted() {
      this.getEducators(0);
    },
    computed : {
      moderatorColSize() {
        return this.getPortion(4);
      },
    },
    methods : {

      /**
       * Get all approved school educators
       * @param currentPage Pagination's current page
       */
      getEducators(currentPage = this.educatorsCurrentPage) {
        this.$http.get('api/approval_request/moderators', {
          params : {
            schoolId : this.$store.getters['user/user'].school_id,
            page     : currentPage + 1,
            perPage  : this.educatorsPerPage,
          },
        }).then(response => {
          this.educatorsCurrentPage = response.data.currentPage;
          this.educatorsLastPage = response.data.lastPage;

          if (this.educatorsCurrentPage > 1) {
            const arr1 = this.educators;
            const arr2 = response.data.data;

            this.educators = arr1.concat(arr2);
          } else this.educators = response.data.data;
          
        }).catch(() => {
          this.educators = [];
        });
      },

      /**
       * Load Educators
       */
      loadMoreEducators() {
        this.getEducators();
      },
    },
    mixins : [
      BreakpointMixin,
    ],
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/moderator/moderator-list";
</style>
